@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Philosopher', sans-serif;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

[type='checkbox'] {
  cursor: pointer;
}
:disabled {
  cursor: not-allowed;
}

.mapboxgl-marker {
  width: 25px;
  height: 25px;
}

.swiper-button-next,
.swiper-button-prev {
  color: white;
}

/* Table style */
.ag-root::-webkit-scrollbar {
  width: 2px;
}
.ag-theme-alpine {
  --ag-grid-size: 5px;
  --ag-list-item-height: 20px;
  --ag-font-size: 12px;
  --ag-font-family: 'Poppins', sans-serif;
}

/* Custom Class */
.shadowRight {
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2);
}
.shadowAll {
  -webkit-box-shadow: 0px 0px 12.5px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: 0px 0px 12.5px 5px rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 12.5px 5px rgba(0, 0, 0, 0.125);
}
.borderNavbar {
  border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
}
.blur {
  filter: blur(2.5px);
  -webkit-filter: blur(2.5px);
  -moz-filter: blur(2.5px);
  -o-filter: blur(2.5px);
  -ms-filter: blur(2.5px);
}
.leftToRight {
  transition-duration: 10s;
  transition-property: all;
  transition-timing-function: ease-in;
}
.hPage {
  height: 100vh-76px;
}

/* Table Style */
.titleTable {
  margin-bottom: 2rem;
  text-align: center;
}
.showData {
  margin-bottom: 1rem;
}
.selectShowData {
  padding: 2.5px;
}

table.table-roster thead {
  top: 0;
  position: sticky;
}
table.table-roster thead th:first-child {
  position: sticky;
  left: 0;
}
table.table-roster tbody tr,
table.table-roster thead tr {
  position: relative;
}
table.table-roster tbody th {
  position: sticky;
  left: 0;
}

#kegiatanHarianPrint img,
#patroliPrint img {
  object-fit: cover;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tags style */
button {
  @apply hover:opacity-75;
}

@layer components {
  .sidebarMenu {
    @apply py-1 px-5 ml-3 text-xs hover:font-semibold cursor-pointer w-full;
  }
  .sidebarMobile {
    @apply px-5 hover:bg-white hover:text-black py-1 cursor-pointer;
  }
  .cardCustom {
    @apply flex justify-between items-center p-5 rounded-xl xl:px-10 text-white;
  }
  .inputLogin {
    @apply border-2 w-[200px] sm:w-[300px];
  }
  .input2 {
    @apply w-full py-2 px-3 rounded-lg border-2 bg-white;
  }
  .btnAksi {
    @apply text-light bg-dark py-0 px-2 my-2 rounded-md flex items-center font-semibold cursor-pointer w-fit hover:opacity-80;
  }
  .btnStatus {
    @apply text-white rounded-md w-fit font-bold px-2;
  }
  .scrollb {
    @apply scrollbar-rounded-lg scrollbar-w-[1px] scrollbar-track-primary scrollbar-thumb-primary scrollbar-corner-primary;
  }
  .pageSetting {
    @apply h-[calc(100vh-100px)] w-[calc(100vw-10px)] xl:w-[calc(100vw-274px)] scrollbar-thin pl-3 xl:px-5 pt-5 overflow-y-scroll 2xl:p-10;
  }
  .aksiDropdown {
    @apply bg-gray-300 text-dark border border-gray-400 ml-10 -mt-1;
  }
  .aksiDropdownItem {
    @apply hover:bg-gray-200 cursor-pointer py-1;
  }
  .topBar {
    border-radius: 30% 70% 54% 46% / 0% 0% 100% 100%;
    @apply w-full h-[30%];
  }
  .navbar {
    border-radius: 30% 70% 54% 46% / 0% 0% 100% 100%;

    @apply w-full h-[160px] scale-150 translate-y-[25%];
  }
  .popOverItem {
    @apply cursor-pointer px-2 rounded-md hover:bg-customGray hover:text-dark;
  }
  .popOverDropdown {
    @apply shadowAll border-0 rounded-lg p-1;
  }
  .inputForm {
    @apply border-2 border-gray-300 rounded-md py-2 px-3 bg-white focus:border-2 focus:border-blue-500 focus:outline-none;
  }
  .invalidForm {
    @apply border-2 border-red-600 rounded-md py-2 px-3 bg-white focus:border-2 focus:border-blue-500 focus:outline-none;
  }
}

.root {
  margin: 0;
  padding: 0;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-bottom: 1rem;
    display: block;
    page-break-after: always;
  }
}

@page {
  size: auto;
}
